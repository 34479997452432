import styled from 'styled-components';

interface AudioButtonProps {
  isMenu?: boolean;
  activeColor: 'pressed' | 'enabled';
}

const colors = {
  pressed: '#E95555',
  enabled: 'transparent',
};

export const VideoButton = styled.button<AudioButtonProps>`
  background: ${props => colors[props.activeColor]};

  padding: 0.5rem;
  width: 2.2rem;
  height: 2.2rem;

  border: ${props => (props.activeColor === 'pressed' ? 'none' : '1px solid var(--smoke)')};
  border-radius: 2rem;

  color: var(--snow);
  font-size: 1.5rem;

  display: flex;
  align-items: center;
  justify-content: center;
`;
