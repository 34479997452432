import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: var(--tofu);
`;

export const InnerContainer = styled.div`
  z-index: 1000;
  position: center;
  display: flex;
  background: var(--snow);
  @media (max-width: 720px) {
    width: 75%;
  }
  @media (min-width: 800px) {
    width: 45%;
  }
  height: 379px;
  overflow: hidden;
  position: relative;
  border-radius: 2.5rem;
  box-shadow: 0px 2px 4px 0px rgba(40, 42, 43, 0.3);
`;

const breatheAnimation = keyframes`
  0% { transform: translate(0,0)}
    50% { transform: translate(-128px,-100px) scale(1)}
    100% { transform: translate(0,0) }
`;

export const Circules = styled.div`
  display: flex;
  z-index: 0;
  transform: scale(0.5);
  @media (min-width: 375px) {
    transform: scale(0.55);
  }
  @media (min-width: 400px) {
    transform: scale(0.8);
  }
  @media (min-width: 1000px) {
    transform: scale(1);
  }

  .circle-1 {
    animation-duration: 10s;
    animation-delay: 0s;
    animation-name: ${breatheAnimation};
    animation-iteration-count: infinite;
    animation-timing-function: ease;
    animation-direction: normal;
    animation-play-state: running;
    animation-fill-mode: none;
    margin-left: -30rem;
    @media (min-width: 375px) {
      margin-left: -40rem;
    }
  }
  .circle-2 {
    animation-duration: 11s;
    animation-delay: 0s;
    animation-name: ${breatheAnimation};
    animation-iteration-count: infinite;
    animation-timing-function: ease;
    animation-direction: normal;
    animation-play-state: running;
    animation-fill-mode: none;
    margin-left: -50rem;
    @media (min-width: 375px) {
      margin-left: -45rem;
    }
    margin-right: -20rem;
  }
`;

interface LogoProps {
  isMobile?: boolean;
}

export const LogoPosition = styled.div<LogoProps>`
  position: absolute;
  left: ${props => (props.isMobile ? '2rem' : '')};
  right: ${props => (props.isMobile ? '2rem' : '1rem')};
  bottom: ${props => (!props.isMobile ? '1rem' : '')};
  transform: ${props => (props.isMobile ? 'scale(.5)' : 'scale(1)')};
  margin-top: ${props => (props.isMobile ? '-.5rem' : '')};
`;
