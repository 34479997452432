import styled from 'styled-components';

export const Button = styled.button`
  background: transparent;
  color: var(--snow);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  width: 2.2rem;
  height: 2.2rem;

  font-size: 1rem;
  border: 1px solid var(--snow);
  border-radius: 99px;
`;
