import React from 'react';
import { DeviceContainer } from '../styles';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import ToggleScreenShareButton from '../../Buttons/ToogleScreenShareButton/ToggleScreenShareButton';

interface MenuButtonsProps {
  disabled?: boolean;
  isMenu: boolean;
}

export default function MenuButtons({ disabled, isMenu }: MenuButtonsProps) {
  return (
    <DeviceContainer isMenu={isMenu}>
      <ToggleAudioButton isMenu={isMenu} disabled={disabled} />
      <ToggleVideoButton isMenu={isMenu} disabled={disabled} />
      <ToggleScreenShareButton />
    </DeviceContainer>
  );
}
