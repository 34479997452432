import styled from 'styled-components';

export const Header = styled.h1`
  font-size: 2rem; //24px
`;

export const RoomName = styled.h2`
  font-size: 2rem; //24px
`;

export const RoomParticipants = styled.p`
  margin-top: 1rem;
`;

export const UserButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-top: 1rem;
`;

export const JoinButton = styled.button`
  background: var(--zero);
  padding: 0.5rem;
  width: 8rem;
  height: 3rem;

  border: none;
  border-radius: 3.625rem;
  box-shadow: 3px 4px 2px -1px #d9dae9;

  font-size: 1rem;
  font-family: 'Jost';
  font-weight: 300;
  color: var(--snow);

  &:hover {
    cursor: pointer;
  }
`;
export const CancelButton = styled.button`
  background: transparent;
  padding: 0.75rem;
  width: 8rem;

  border: 0.75px solid #d9dae9;
  border-radius: 2rem;
  box-shadow: 3px 4px 2px -1px #d9dae9;

  font-size: 1rem;
  font-weight: 400;
  color: var(--night);

  &:hover {
    cursor: pointer;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem; //It will be removed once we got participants in the room
  @media (min-width: 620px) {
    margin-top: 3rem;
  }
`;
interface ContainerProps {
  isMobile: boolean;
}
export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${props => (props.isMobile ? '3rem' : '')};
`;

export const VideoPreview = styled.div``;

export const DeviceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export const SettingsButton = styled.button`
  background: transparent;
  padding: 0.75rem;
  width: 2rem;
  height: 2rem;

  border: 0.75px solid #d9dae9;
  border-radius: 2rem;

  font-size: 1rem;
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  position: absolute;
  right: 1rem;
  top: 1rem;

  &:hover {
    cursor: pointer;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 2rem;
  line-height: 1rem;

  font-size: 1.125rem;
  font-weight: 400;
  color: var(--night);
  border: none;
  border-bottom: 1px solid var(--night);

  margin-bottom: 1rem;
  margin-top: 1rem;
  text-align: center;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-left: auto;
  margin-right: auto;
`;

export const DeviceSelectionScreenMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
`;

export const DeviceSelectionScreenSecondary = styled.div`
  @media (min-width: 1000px) {
    display: flex;
    flex-direction: row;
    gap: 5rem;
  }
`;
