import styled from 'styled-components';

export const RadialButton = styled.button`
  height: 3rem;
  width: 3rem;
  background: transparent;
  border: none;
  position: absolute;
  top: 0.5rem;
  left: 0;

  color: var(--snow);
`;
