import styled from 'styled-components';

export const Footer = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  margin-top: 0rem;

  max-width: 100%;
  height: 10rem;
  width: 100%;
  background-color: transparent;
`;

export const AVContainer = styled.div`
  margin-top: 1rem;
`;

export const IntegrationContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  gap: 1rem;
`;

export const RadialButton = styled.button`
  margin-left: auto;
  margin-right: auto;

  background: #fff;
  border: none;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 10rem;
  height: 2rem;

  p {
    margin-left: 1rem;
  }

  transition: filter 0.2s;
  &:hover {
    filter: brightness(0.9);
  }
`;

export const RadialCloseButton = styled.button`
  margin-top: 1.5rem;
  margin-left: 1.5rem;
  background: #fff;
  border: none;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 10rem;
  height: 2rem;

  p {
    margin-left: 1rem;
  }

  transition: filter 0.2s;
  &:hover {
    filter: brightness(0.9);
  }
`;

interface DeviceContainerProps {
  isMenu?: boolean;
}

export const DeviceContainer = styled.div<DeviceContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${props => (props.isMenu ? '1rem' : '2rem')};
`;
