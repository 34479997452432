import React from 'react';
import ToggleAudioButton from '../../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../../Buttons/ToggleVideoButton/ToggleVideoButton';
import { DeviceContainer } from '../DSStyles';

interface DeveiceButtonsProps {
  disabled?: boolean;
  isMobile: boolean;
}

export default function DeveiceButtons({ disabled, isMobile }: DeveiceButtonsProps) {
  return (
    <DeviceContainer>
      <ToggleAudioButton isMenu={false} disabled={disabled} />
      <ToggleVideoButton isMenu={false} disabled={disabled} />
    </DeviceContainer>
  );
}
