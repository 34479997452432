import { ChangeEvent, FormEvent } from 'react';
import { FormSubmit, Header, RadioBox, Input, Container } from './Styles';

interface RoomNameScreenProps {
  name: string;
  roomName: string;
  setName: (name: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  setRoomName: (roomName: string) => void;
}

export default function RoomNameScreen({ name, roomName, setName, handleSubmit, setRoomName }: RoomNameScreenProps) {
  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomName(event.target.value);
  };

  return (
    <Container>
      <Header>Create a Room</Header>

      <FormSubmit onSubmit={handleSubmit}>
        <Input placeholder="Your name" id="input-user-name" value={name} onChange={handleNameChange} />

        <Input placeholder="Room name" id="input-user-name" value={roomName} onChange={handleRoomNameChange} />

        <RadioBox type="submit" disabled={!name || !roomName}>
          Continue
        </RadioBox>
      </FormSubmit>
    </Container>
  );
}
