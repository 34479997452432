import React from 'react';
import { RadialButton } from './styles';
import { CallEnd } from '@material-ui/icons';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

export default function EndCallButton() {
  const { room } = useVideoContext();

  return (
    <RadialButton onClick={() => room!.disconnect()} data-cy-disconnect>
      <CallEnd fontSize="small" />
    </RadialButton>
  );
}
