import React from 'react';
import AnimatedCircles from './assets';
import { useAppState } from '../../state';

import VideoLogo from './assets/VideoLogo';

import { Theme, useMediaQuery } from '@material-ui/core';
import { Container, InnerContainer, LogoPosition } from './introContainerStyles';
interface IntroContainerProps {
  children: React.ReactNode;
}

export default function IntroContainer(props: IntroContainerProps) {
  const { user } = useAppState();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <>
      <Container>
        <AnimatedCircles />
        <InnerContainer>
          {isMobile && (
            <LogoPosition isMobile={isMobile}>
              <VideoLogo />
            </LogoPosition>
          )}
          {props.children}
        </InnerContainer>
      </Container>
      {!isMobile && (
        <LogoPosition isMobile={isMobile}>
          <VideoLogo />
        </LogoPosition>
      )}
    </>
  );
}
