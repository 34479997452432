import styled from 'styled-components';

export const InnerFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  position: absolute;

  bottom: 0.25rem;
  z-index: 1;

  gap: 3rem;
`;
export const IndicatorPosition = styled.div`
  position: absolute;
  left: 2rem;
  bottom: 0;
`;
export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 14vh;
  width: 30vh;

  margin-top: 1rem;

  padding-top: 56.25%;
  background: black;
  box-shadow: -1px 2px 5px #d9dae9;
  border-radius: 2px;
`;

export const InnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const RadialButton = styled.button`
  position: absolute;
  right: 1rem;

  padding: 0.75rem;
  width: 3rem;
  height: 3rem;

  border: 1px solid var(--smoke);
  border-radius: 2rem;

  color: var(--snow);
  font-size: 1.5rem;
  background-color: transparent;
  border: none;
`;

export const CameraWarning = styled.h1`
  color: var(--snow);
  font-family: 'Jost';
  text-align: center;
  margin-top: 2rem;
  font-size: 1.5rem;
`;
