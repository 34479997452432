import styled, { keyframes } from 'styled-components';

export const ChatButton = styled.button`
  color: var(--snow);

  width: 2.2rem;
  height: 2.2rem;

  border: 1px solid var(--snow);
  border-radius: 10rem;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
`;

export const IconContainer = styled.div`
  position: relative;
  display: flex;
`;

interface UnreadMessageProps {
  hasUnreadMessages: boolean;
}

export const UnreadMessage = styled.div<UnreadMessageProps>`
  background: var(--failure);
  border-radius: 30px;
  height: 0.75rem;
  width: 0.75rem;
  position: absolute;
  left: 3px;
  top: 2rem;
  opacity: ${props => (props.hasUnreadMessages ? '1' : '0')};
`;

interface AnimatedRigProps {
  shouldAnimate: boolean;
}

const breatheAnimation = keyframes`
    0%: {
      transform: 'scale(0.1, 0.1)',
      opacity: 0,
    }
    50%: {
      opacity: 1,
    }
    100%: {
      transform: 'scale(1.4, 1.4)',
      opacity: 0,
    },
  `;

export const AnimatedRing = styled.div<AnimatedRigProps>`
  border: 3px solid var(--peach);
  border-radius: 30px;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 11px;
  top: -5px;
  opacity: 0;

  animation-duration: 0.7s;
  animation-delay: 0s;
  animation-name: ${breatheAnimation};
  animation-iteration-count: 2;
  animation-timing-function: ease-out;
  animation-direction: normal;
`;
