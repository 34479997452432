import React from 'react';
import { AVContainer, Footer, IntegrationContainer } from './styles';
import Button from '@material-ui/core/Button';
import { Typography, Grid } from '@material-ui/core';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ToggleChatButton from '../Buttons/ToggleChatButton/ToggleChatButton';
import MenuButtons from './components';
import Menu from './Menu/Menu';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    screenShareBanner: {
      position: 'fixed',
      zIndex: 8,
      bottom: `${theme.footerHeight}px`,
      left: 0,
      right: 0,
      height: '104px',
      background: 'rgba(0, 0, 0, 0.5)',
      '& h6': {
        color: 'white',
      },
      '& button': {
        background: 'white',
        color: theme.brand,
        border: `2px solid ${theme.brand}`,
        margin: '0 2em',
        '&:hover': {
          color: '#600101',
          border: `2px solid #600101`,
          background: '#FFE9E7',
        },
      },
    },
  })
);

export default function MenuBar() {
  const classes = useStyles();
  const { isSharingScreen, toggleScreenShare } = useVideoContext();
  // const roomState = useRoomState();
  // const isReconnecting = roomState === 'reconnecting';
  // const { room } = useVideoContext();

  return (
    <>
      {isSharingScreen && (
        <Grid container justifyContent="center" alignItems="center" className={classes.screenShareBanner}>
          <Typography variant="h6">You are sharing your screen</Typography>
          <Button onClick={() => toggleScreenShare()}>Stop Sharing</Button>
        </Grid>
      )}
      <Footer>
        <EndCallButton />
        <AVContainer>
          <MenuButtons isMenu={true} />
        </AVContainer>
        <IntegrationContainer>
          <Menu />
          <ToggleChatButton />
        </IntegrationContainer>
      </Footer>
    </>
  );
}
