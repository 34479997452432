import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
:root{
  // Prymary Palette
  --zero:  #0073fb;
  --night: #000000;
  --tofu:  #f2f6f7;
  --snow:  #ffffff;
  // Secondary Palette
  --sonic:     #0025b2;
  --lightning: #4dc8e9;
  --pac-man:   #ffd377;
  --peach:     #ff8089;
  --widow: #cabbfd;
  --smoke:   #D9DAE9;
  // Alert Palette
  --warning: #f3c03a;
  --success: #56e153;
  --failure: #e95555;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: 0;

  html{ 
    @media(max-width: 1080px){
      font-size: 93.75%;
    }
    @media(max-width: 720px){
      font-size: 87.5%;
    }
  }
  body{ 
        background-color: var(--snow);
        -webkit-font-smoothing: antialiased;
    }

    body, input, textarea, h5, button{
        font-family: 'Work Sans', sans-serif;
        font-weight: 400;
    }

    h1, h2, h3, h4, h6, strong{
      font-family: 'Jost', sans-serif;
      font-weight: 500;
    } 

    button{
        cursor: pointer;
    }

}

`;
