import styled from 'styled-components';

export const Sharebutton = styled.button`
  background: transparent;
  width: 2.2rem;
  height: 2.2rem;

  color: var(--snow);

  border: 1px solid var(--snow);
  border-radius: 100px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
