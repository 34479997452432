import styled from 'styled-components';

export const Container = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 45vh;
  @media (min-width: 600px) {
    width: 100vh;
  }
`;

export const Header = styled.h1`
  margin-bottom: 1rem;
`;

export const FormSubmit = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const RadioBox = styled.button`
  background: var(--zero);
  padding: 1rem;
  width: 10rem;

  border: none;
  border-radius: 3.625rem;
  box-shadow: 3px 4px 2px -1px #d9dae9;

  font-size: 1.25rem;
  color: var(--snow);

  &:hover {
    cursor: pointer;
  }
`;

export const Labels = styled.label`
  font-size: 18px;
`;

export const Input = styled.input`
  width: 150px;
  height: 2rem;
  line-height: 1rem;

  font-size: 1.125rem;
  font-weight: 400;
  color: var(--night);
  border: none;
  border-bottom: 1px solid var(--night);

  margin-bottom: 2rem;

  ::placeholder {
    color: var(--gray-150);
  }
  &:first-child {
    margin-bottom: 2rem;
  }
`;

export const SwitchContainer = styled.div`
  display: inline;
`;

export const SwitchLabel = styled.span`
  fort
`;
