import React from 'react';
import { AudioButton } from './Styles';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import { Mic, MicOff } from '@material-ui/icons';
interface ToggleAudioButtonProps {
  disabled?: boolean;
  className?: string;
  isMenu?: boolean;
}

export default function ToggleAudioButton({ disabled, className, isMenu }: ToggleAudioButtonProps) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideoContext();
  const hasAudioTrack = localTracks.some(track => track.kind === 'audio');

  return (
    <AudioButton
      isMenu={isMenu}
      activeColor={isAudioEnabled ? 'enabled' : 'pressed'}
      onClick={toggleAudioEnabled}
      disabled={!hasAudioTrack || disabled}
      data-cy-audio-toggle
    >
      {isAudioEnabled ? <Mic fontSize="small" /> : <MicOff fontSize="small" />}
    </AudioButton>
  );
}
