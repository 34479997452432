import styled from 'styled-components';

export const RadialButton = styled.button`
  margintop: '1rem';
  background: var(--failure);

  width: 2.2rem;
  height: 2.2rem;

  border-radius: 10rem;
  border: none;

  margin-top: 1rem;
  color: var(--snow);
`;
