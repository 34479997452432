import React from 'react';
import LocalAudioLevelIndicator from '../../../LocalAudioLevelIndicator/LocalAudioLevelIndicator';
import { LocalVideoTrack } from 'twilio-video';
import VideoTrack from '../../../VideoTrack/VideoTrack';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';
import DeveiceButtons from '../components/DeviceButtons';
import { useAppState } from '../../../../state';
import { CameraWarning, Container, IndicatorPosition, InnerContainer, InnerFooter } from './LVPstyles';
import SettingsMenu from '../SettingsMenu/SettingsMenu';

export default function LocalVideoPreview() {
  const { localTracks, isAcquiringLocalTracks, isConnecting } = useVideoContext();
  const { isFetching } = useAppState();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;

  const videoTrack = localTracks.find(
    track => !track.name.includes('screen') && track.kind === 'video'
  ) as LocalVideoTrack;

  return (
    <Container>
      <InnerContainer>
        {videoTrack ? <VideoTrack track={videoTrack} isLocal /> : <CameraWarning>Camera is off</CameraWarning>}
      </InnerContainer>

      <InnerFooter>
        <IndicatorPosition>
          <LocalAudioLevelIndicator />
        </IndicatorPosition>
        <DeveiceButtons isMobile={false} disabled={disableButtons} />
      </InnerFooter>
      <SettingsMenu />
    </Container>
  );
}
