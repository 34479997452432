import styled from 'styled-components';

export const Header = styled.h1`
  fontSize: '48px',
  marginBottom: '1em',  
`;

export const GoogleButton = styled.button`
  background: var(--tofu);
  color: var(--sonic);
  border-radius: 0.5rem;
  border: 2px solid var(--lightning);
  margin: 1.8em 0 0.7em;
  text-transform: none;
  box-shadow: none;
  padding: 0.5rem 1rem;
  &:hover: {
    background: var(--snow);
  }
`;
