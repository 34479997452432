import React, { ChangeEvent } from 'react';
import { makeStyles, Typography, Grid, Theme, Input } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
import { Steps } from '../PreJoinScreens';
import { useAppState } from '../../../state';
import ActionButtons from './components/ActionBottuns';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import useRoomState from '../../../hooks/useRoomState/useRoomState';
import { useMediaQuery } from '@material-ui/core';
import {
  HeaderWrapper,
  Container,
  Header,
  InnerContainer,
  RoomName,
  RoomParticipants,
  VideoPreview,
  DeviceSelectionScreenMain,
  DeviceSelectionScreenSecondary,
} from './DSStyles';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    fontSize: '16px',
    fontFamily: 'Nunito',
    fontWeight: 400,
    marginBottom: '15px',
    marginTop: '-15px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      marginBottom: '15px',
      marginTop: '-15px',
    },
  },
  username: {
    fontSize: '16px',
    fontFamily: 'Nunito',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
    },
  },

  deviceButton: {
    width: '100%',
    border: 'none',
    marginTop: '5px',
    display: 'in-line',
    alignItems: 'start',
    justifyContent: 'start',

    fontSize: '16px',
    fontFamily: 'Nunito',
    fontWeight: 700,
    paddingLeft: '15px',
  },

  localPreviewContainer: {
    paddingRight: '2em',
    marginBottom: '10px',
    marginTop: '10px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2.5em',
    },
  },

  radialButtons: {
    width: '100%',
  },

  joinButton: {
    fontSize: '16px',
    fontFamily: 'Nunito',
    fontWeight: 400,

    background: '#2D67BA',
    color: '#fff',

    border: 'none',
    borderRadius: '43.5px',

    boxShadow: ' 0px 8px 13px 3px rgba(0, 0, 0, 0.25)',

    width: '160px',
    height: '47px',
    marginTop: '25px',
    marginLeft: '70px',

    [theme.breakpoints.down('sm')]: {
      padding: '5px',

      width: '35%',
      marginTop: '-20px',
      fontSize: '18px',
      marginLeft: '30px',
    },
  },

  cancelButton: {
    fontSize: '16px',
    fontFamily: 'Nunito',
    fontWeight: 400,
    padding: '10px',
    color: '#2D67BA',
    background: 'none',
    border: '2px solid #2D67BA',
    borderRadius: '43.5px',

    width: '120px',
    heigth: '80px',
    marginTop: '20px',
    marginRight: '100px',
    marginLeft: '0px',

    [theme.breakpoints.down('sm')]: {
      marginLeft: '35px',
      marginRight: '10px',
      marginTop: '20px',
      width: '30%',
      height: '50px',
      fontSize: '16px',
    },
  },
  mobileButtonBar: {
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-around',
      margin: '1.5em 0 1em',
      color: '#000',
    },
  },
  mobileButton: {
    fontSize: '16px',
    padding: '0.8em 0',
    margin: 0,
  },

  mobileSetting: {
    fontSize: '16px',
    position: 'absolute',
    bottom: '300px',
    right: '2px',
  },
  bookingDetails: {
    color: 'white',
    padding: '2px',
    lineHeight: '15px',
    fontSize: '18px',
    fontFamily: 'Nunito',
    fontWeight: 400,
    position: 'absolute',
    left: '30px',
    bottom: '20px',
  },
}));

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
  setStep: (step: Steps) => void;
  setName: (name: string) => void;
}

export default function DeviceSelectionScreen({ name, roomName, setStep, setName }: DeviceSelectionScreenProps) {
  const classes = useStyles();
  const { getToken, isFetching } = useAppState();
  const { connect: chatConnect } = useChatContext();
  const { connect: videoConnect, isAcquiringLocalTracks, isConnecting } = useVideoContext();
  const roomState = useRoomState();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const searchParams = Object.fromEntries(urlSearchParams.entries());
  const room: string = searchParams.room ?? '';

  const handleJoin = () => {
    getToken(name, roomName).then(({ token }) => {
      videoConnect(token);
      process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && chatConnect(token);
    });
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  if (isFetching || isConnecting) {
    return (
      <Grid container justifyContent="center" alignItems="center" direction="column" style={{ height: '100%' }}>
        <div>
          <CircularProgress variant="indeterminate" />
        </div>
        <div>
          <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '16px' }}>
            You are Joining
          </Typography>
        </div>
      </Grid>
    );
  }

  return (
    <DeviceSelectionScreenMain>
      {isMobile ? (
        <></>
      ) : (
        <HeaderWrapper>
          <Header>Join</Header>
          <RoomName>{roomName}</RoomName>
        </HeaderWrapper>
      )}
      <DeviceSelectionScreenSecondary>
        <Container isMobile={isMobile}>
          <VideoPreview>
            <LocalVideoPreview />
          </VideoPreview>
        </Container>

        <InnerContainer>
          {/* <RoomParticipants>Participants online</RoomParticipants> */}
          <Input id="input-user-name" value={name} onChange={handleNameChange} placeholder="Your name" />
          <ActionButtons
            setStep={setStep}
            handleJoin={handleJoin}
            disableButtons={disableButtons}
            roomName={roomName}
          />
        </InnerContainer>
      </DeviceSelectionScreenSecondary>
    </DeviceSelectionScreenMain>
  );
}
