import { ModeCommentOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { ChatButton, IconContainer, UnreadMessage } from './Styles';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

export const ANIMATION_DURATION = 700;

export default function ToggleChatButton() {
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const { isChatWindowOpen, setIsChatWindowOpen, conversation, hasUnreadMessages } = useChatContext();
  const { setIsBackgroundSelectionOpen } = useVideoContext();

  const toggleChatWindow = () => {
    setIsChatWindowOpen(!isChatWindowOpen);
    setIsBackgroundSelectionOpen(false);
  };

  useEffect(() => {
    if (shouldAnimate) {
      setTimeout(() => setShouldAnimate(false), ANIMATION_DURATION);
    }
  }, [shouldAnimate]);

  useEffect(() => {
    if (conversation && !isChatWindowOpen) {
      const handleNewMessage = () => setShouldAnimate(true);
      conversation.on('messageAdded', handleNewMessage);
      return () => {
        conversation.off('messageAdded', handleNewMessage);
      };
    }
  }, [conversation, isChatWindowOpen]);

  return (
    <ChatButton data-cy-chat-button onClick={toggleChatWindow} disabled={!conversation}>
      <IconContainer>
        <ModeCommentOutlined fontSize="small" />
        <UnreadMessage hasUnreadMessages={hasUnreadMessages} />
      </IconContainer>
    </ChatButton>
  );
}
