import React from 'react';
import { UserButtons, JoinButton } from '../DSStyles';
import { Steps } from '../../PreJoinScreens';
import { useMediaQuery, Theme } from '@material-ui/core';

interface ActionButtonsProps {
  setStep: (step: Steps) => void;
  handleJoin: () => void;
  disableButtons: boolean;
  roomName?: string;
}

export default function ActionButtons({ setStep, handleJoin, disableButtons, roomName }: ActionButtonsProps) {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <UserButtons>
      <JoinButton onClick={handleJoin} disabled={disableButtons}>
        {isMobile ? <>Join {roomName}</> : 'Join Now'}
      </JoinButton>
    </UserButtons>
  );
}
