import circle1 from '../../../assets/Elipse1.svg';
import circle2 from '../../../assets/Elipse2.svg';
import { Circules } from '../introContainerStyles';

export default function AnimatedCircles() {
  return (
    <Circules>
      <img src={circle1} className="circle-1" alt="circle1" />
      <img src={circle2} className="circle-2" alt="circle2" />
    </Circules>
  );
}
