import { VideoButton } from './Styles';
import { useCallback, useRef } from 'react';
import useDevices from '../../../hooks/useDevices/useDevices';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';
import { VideocamOutlined, VideocamOffOutlined } from '@material-ui/icons';
interface ToggleVideoButtonProps {
  disabled?: boolean;
  className?: string;
  isMenu?: boolean;
}

export default function ToggleVideoButton({ disabled, isMenu }: ToggleVideoButtonProps) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { hasVideoInputDevices } = useDevices();

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  return (
    <VideoButton
      isMenu={isMenu}
      activeColor={isVideoEnabled ? 'enabled' : 'pressed'}
      onClick={toggleVideo}
      disabled={!hasVideoInputDevices || disabled}
    >
      {isVideoEnabled ? <VideocamOutlined fontSize="small" /> : <VideocamOffOutlined fontSize="small" />}
    </VideoButton>
  );
}
